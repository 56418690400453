(function() {
    'use strict';

    angular.module("aerosApp")
        .controller("ProjectCtrl",ProjectController);

    ProjectController.$inject = ["$rootScope", "$scope", "aerosApi", "$state", "$stateParams", "$timeout", "$log",
        "userInvitationModal", "$uibModal", "addUserModal", "addRogueUnitModal", "$filter", "$translate", "$q",
        "Notification", "rbacService", "$location", "allPermissions", "allRoles", "FiberGroupService", "routesConstant",
        "PageService", "ProjectService", "TestResultsService"];

    function ProjectController($rootScope, $scope, aerosApi, $state, $stateParams, $timeout, $log, userInvitationModal,
                               $uibModal, addUserModal, addRogueUnitModal, $filter, $translate, $q, Notification,
                               rbacService, $location, allPermissions, allRoles, FiberGroupService, routesConstant,
                               PageService, ProjectService, TestResultsService) {

        var CUSTOMER = $scope.CUSTOMER = routesConstant.CUSTOMER;
        var LOGIN = routesConstant.LOGIN;

        $scope.$watch("commonReady", function (newVal) {
            if (!newVal) return;

            var refreshFrequency = 10000;
            var refreshTimer = undefined;
            var projectLastUpdated = 0;
            var updatingProject = false;

            (function init() {
                $scope.projectId = $stateParams.id;
                $scope.project = {};
                $scope.addTech = {};
                $scope.roles = {};
                $scope.projectReady = false;
                $scope.arrayRemovedTools = [0];
                $scope.clicked = false;

                $translate('Title.Project').then(function (translation) {
                    $scope.titleText = translation;
                });

                TestResultsService.getExistingSummaryResults($scope.projectId, $scope.organization.id)
                    .then(dataExists => $scope.dataExists = dataExists);

                bindScope();
                bindCleanup();
                loadProject();
                getUserProjectInfo();
                pollProject();

                $scope.roleNameById = {};
                $scope.roleRankById = {};
                $scope.rolesTooltip = "";
                _.each(allRoles["projectRoles"], function (role) {
                    $scope.roleNameById[role.id] = role.name;
                    $scope.roleRankById[role.id] = role.rank;
                    $scope.rolesTooltip += "• " + role.name + ": " + role.description + " ";
                });

                $scope.showRoleSelection = true;
                $scope.requireOneRole = true;

            })();

            function bindScope() {
                angular.extend($scope, {
                    loadProject: getProjectIfChanged,
                    updateFGsAfterCopy: updateFGsAfterCopy,
                    updateFGsAfterAdd: updateFGsAfterAdd,
                    updateFGsAfterDelete: updateFGsAfterDelete,
                    bestRankedId: bestRankedId,
                    addProjectUser: addProjectUser,
                    addUsersToProjectByRole: addUsersToProjectByRole,
                    notifyTeamMembers: notifyTeamMembers,
                    removeMe: removeMe,
                    deleteProjectUser: deleteProjectUser,
                    editUserProjectRole: editUserProjectRole,
                    userInvitation: userInvitation,
                    userReset: userReset,
                    showProjectResults: showProjectResults,
                    deselectProjects: deselectProjects,
                    getTeam: getTeam
                })
            }

            function pollProject() {
                if ($scope.arrayRemovedTools.length > 1 || $scope.clicked) {
                    $scope.clicked = false;
                    $timeout(function () {
                        pollProject();
                    }, 3000);

                } else {
                    getProjectIfChanged().then(function (data) {
                        //$log.info("restarting timer");
                        refreshTimer = $timeout(pollProject, refreshFrequency);
                    });
                }

                //$log.info("updatingProject is "+updatingProject);
            }

            function bindCleanup() {
                $scope.$on("$destroy", function (event) {
                    // clean up polling
                    $timeout.cancel(refreshTimer);
                });
            }

            function getProjectIfChanged(force) {

                var deferred = $q.defer();
                if (updatingProject == true) {
                    //$log.info("getProjectIfChanged: Already Updating Project - skipping");
                    deferred.resolve("skipping");
                    return deferred.promise;
                }
                updatingProject = true;

                var projectId = $scope.projectId;
                //$log.info("Loading project changed %s", projectId);
                return aerosApi.getProjectChanged(projectId, projectLastUpdated, $scope.organization.id)
                    .then(function (response) {
                        var data = response.data;

                        return data;
                    }).then(function (data) {

                        if (data.changes == true || force) {
                            //$log.info("changed is true, load project");
                            return loadProject();
                        } else {
                            updatingProject = false;
                            //$log.info("changed is false not loading project");
                        }
                        return data;
                    });
            }

            function loadProject() {
                $scope.projectReady = false;

                var projectId = $scope.projectId;
                //$log.info("Loading project %s", projectId);
                updatingProject = true;
                return ProjectService.fetchProject(projectId, $scope.organization.id)
                    .then(function (response) {
                        var data = response.data;
                        $scope.project = data;

                        _.each(data.fiberGroups, function (fiber) {
                            _.each(fiber.collections, function (collection) {
                                var count = collection.attachments.length;
                                _.each(collection.fibers, function (f) {
                                    count += f.attachments.length;
                                });
                                collection.countOfAttahcment = count;
                            });

                        });

                        projectLastUpdated = data.now;

                        PageService.setTitle($scope.titleText + ": " + $scope.project.name);
                        return data;
                    })
                    .then(function (data) {
                        $scope.enableAnimations = true;
                        updatingProject = false;
                        //$log.info("Resetting updatingProject to "+updatingProject);
                        getAccess();
                        return data;
                    });
            }

            function getUserProjectInfo() {
                var projectId = $scope.projectId;
                aerosApi.loadProjectUserInfo(projectId, $scope.organization.id).then(function (response) {
                    if (response && response.data) {
                        $scope.roles = response.data.roles;
                        getAccess();
                    }
                }, function (err) {
                    // reject if "unauthorized" or "forbidden"
                    if (err.status === 403 || err.status === 401) {
                        $state.go(LOGIN.LOGOUT.stateName);
                    }
                });
            }

            function getAccess() {
                rbacService.getScope($scope);
                rbacService.setAllPermissions(allPermissions);
                $scope.allow = {};
                _.each(_.keys(allPermissions.permissions), function (permission) {
                    $scope.allow[permission] = rbacService.checkAccess(permission);
                });
                _.each(_.keys(allPermissions.accountPermissions), function (permission) {
                    $scope.allow[permission] = rbacService.checkAccess(permission);
                });

                //$scope.allow.sampleResults = $scope.$parent.allow.sampleResults;
                if (!$scope.allow['viewProject']) {
                    return $scope.reject();
                }
                if (_.includes($state.$current.url.segments, "/team") && $scope.allow['viewTeam']) {
                    $scope.getTeam();
                }

                $scope.me = $scope.user;

                if ($scope.allow["editTeam"]) {
                    $scope.availableRoles = allRoles.projectRoles;
                } else if ($scope.allow["assignTechOnly"]) {
                    $scope.availableRoles = _.filter(allRoles.projectRoles, function (role) {
                        return role.id === "technician";
                    });
                } else {
                    $scope.availableRoles = [];
                }
                $scope.selectedRoles = _.take(_.sortBy($scope.availableRoles, function (role) {
                    return role.rank * -1;
                }));

                $scope.projectReady = true;
            }

            function updateFGsAfterCopy(response) {
                if (response.data && response.data.length > 0) {

                    response.data.forEach(function (newFg) {
                        var originalFG = $scope.project.fiberGroups.find(function (item) {
                            return item.id === newFg.oldFGId;
                        });

                        if (originalFG) {
                            originalFG = Object.assign({}, originalFG);
                            originalFG.name = newFg.newFGName;
                            originalFG.id = newFg.newFGId;
                            originalFG.uuid = newFg.newFGUuid;
                        }

                        $scope.project.fiberGroups.unshift(originalFG);
                    });
                }

                return response;
            }

            function updateFGsAfterAdd(response) {
                if (response.data && response.data.newlyCreatedFiberGroups.length > 0) {
                    $scope.project.fiberGroups = $scope.project.fiberGroups
                        .concat(response.data.newlyCreatedFiberGroups);
                }

                return response;
            }

            function updateFGsAfterDelete(response) {
                if (response.data && response.data.length > 0) {
                    var fgsToDelete = response.data;

                    $scope.project.fiberGroups = $scope.project.fiberGroups
                        .filter(function (fg) {
                            return fgsToDelete.find(function (item) {
                                if (item.markedForDelete && item.id === fg.id) {
                                    fg.markedForDelete = true;
                                }
                                return item.id === fg.id && !item.markedForDelete;
                            }) === undefined;
                        });
                }
            }

            function bestRankedId(roles) {
                var bestRank = 999;
                var bestRankedId = "";
                _.each(roles, function (role) {
                    var roleLower = role.role.toLowerCase();
                    var roleRank = $scope.roleRankById[role.role.toLowerCase()];
                    if (roleRank < bestRank) {
                        bestRank = roleRank;
                        bestRankedId = roleLower;
                    }
                });
                return bestRankedId;
            }

            function addProjectUser() {

                var availableLicenses =
                    [
                        {
                            id: $scope.user.licenseKey,
                            label: $scope.user.licenseKey
                        }
                    ];
                //var availableRoles= [{rank: 3, id: "user", name: "Basic User", description: "May be assigned to projects"}];
                //$scope.selectedRoles=["user"];

                userInvitationModal.open("Invite a new team member", false, availableLicenses, $scope.organization.id, $scope.availableRoles, $scope.selectedRoles, $scope.showRoleSelection, $scope.requireOneRole).then(function (user) {
                    user.projectId = $scope.projectId;
                    //Add user to license first, then if successful, add to project.
                    var orgUser = angular.copy(user);
                    orgUser.roles = ["user"];

                    aerosApi.inviteUser(orgUser, user.licenseKey)
                        .success(function () {
                            Notification.success("User added to Organization");
                            aerosApi.inviteProjectUser(user)
                                .success(function () {
                                    Notification.success("User added to Team");
                                    $scope.getTeam();
                                })
                                .error(function (err) {
                                    Notification.error("User not invited. " + err.message);
                                });
                        })
                        .error(function (err) {
                            Notification.error("User not invited. " + err.message);
                        });

//			aerosApi.inviteUser(orgUser, user.licenseKey).then(
//				aerosApi.inviteProjectUser(user)).then(function(data) {
//					$scope.getTeam();
//			});
                });
            }

            function getOrgUsers() {
                if (typeof $scope.users === "undefined") {
                    // TODO: ? ever need to get orgId?
                    return aerosApi.loadOrganizationUsers($scope.organization.id);
                } else {
                    var deferred = $q.defer();
                    deferred.resolve();
                    return deferred.promise;
                }
            }

            function addUsersToProjectByRole() {
                getOrgUsers().then(function (rtn) {
                    if (rtn && rtn.data) {
                        $scope.users = rtn.data.users;
                    }
                    var userList = _.map(_.filter($scope.users, function (orgUser) {
                        return !(orgUser.status == "Suspended") && !_.some($scope.team, {'username': orgUser.username});
                    }), function (user) {
                        return {
                            email: user.email,
                            firstName: user.firstName,
                            lastName: user.lastName,
                            organizationId: $scope.organization.id,
                            projectId: $scope.projectId,
                            roles: [],
                            selected: false
                        };
                    });
                    addUserModal.open($scope.project.name, userList, $scope.availableRoles, $scope.addProjectUser).then(function (selectedUsers) {
                        if (selectedUsers.length) {
                            aerosApi.inviteProjectUsers(selectedUsers).then(function () {
                                $scope.getTeam();
                            });
                        }
                    });
                });
            }

            function notifyTeamMembers() {
                return aerosApi.notifyTeamMembers($scope.projectId, $scope.organization.id).then(function (data) {
                    // after API call returns show a message on screen saying that Team Member Notifications have been scheduled.
                    Notification.success("Team Member notifications have been scheduled.");
                });
            }

            function redirectToProjects() {
                $location.path("/projects");
            }

            function removeMe() {
                $scope.deleteProjectUser($scope.me, redirectToProjects);
            }

            function deleteProjectUser(user, cb) {
                if (user.username === $scope.me.username) {
                    cb = redirectToProjects;
                }
                $uibModal.open({
                    size: "md",
                    resolve: {
                        user: function () {
                            return user;
                        }
                    },
                    templateUrl: 'confirmDeleteTeamMemberModal.html',
                    controller: ["$scope", "user", function ($scope, user) {
                        $scope.fullName = user.firstName + " " + user.lastName;
                    }]
                }).result.then(function () {
                    return aerosApi.deleteProjectUser(user.username, $scope.projectId, $scope.organization.id).then(function (data) {
                        if (typeof cb === "function") {
                            cb();
                        }
                        $scope.getTeam();
                        Notification.success("Deleted User: " + user.firstName + " " + user.lastName);
                    });
                });
            }

            function editUserProjectRole(user) {
                var passUser = {
                    email: user.email,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    licenseKey: user.licenseKey,
                    organizationId: $scope.organization.id,
                    roles: _.map(user.roles.aerosRoles, "role") || []
                };
                var availableLicenses = [
                    {
                        id: user.licenseKey,
                        label: user.licenseKey
                    }];
                userInvitationModal.open('Edit role of ' + user.firstName + ' ' + user.lastName + ' in "' + $scope.project.name + '"',
                    true,
                    $scope.organization.id,
                    availableLicenses,
                    $scope.availableRoles,
                    _.map(user.roles.aerosRoles, function (role) { // selectedRoles
                        return _.find($scope.availableRoles, {id: role.role});
                    }),
                    true, true, // showRoleSelection, requireOneRole
                    passUser
                ).then(function (user) {
                    user.projectId = $scope.projectId;
                    aerosApi.inviteProjectUser(user).then(function (data) {
                        $scope.getTeam();
                    });
                });
            }

            function userInvitation(username, orgId) {
                aerosApi.userInvitationEmail(username, orgId).then(
                    function (response) {
                        Notification.success("A user activation email has been sent to " + username);
                    },
                    function (error) {
                        Notification.error("Error encountered sending user activation email to " + username);
                    })
            };

            function userReset(username) {
                aerosApi.sendResetEmail(username).then(
                    function (response) {
                        Notification.success("A reset-password email has been sent to " + username);
                    },
                    function (error) {
                        Notification.error("Error encountered sending reset-password email to " + username);
                    })
            };

            function showProjectResults(resultType) {
                $state.go(CUSTOMER.PROJECTS_PROJECT_RESULTS.stateName, {
                    id: $scope.projectId,
                    testType: 'summary',
                    resultType: resultType
                });
            }

            function deselectProjects() {
                $timeout(function () {
                    $('.header .active').removeClass('active');
                }, 100);
            }

            function getTeam() {
                var projectId = $scope.projectId;
                aerosApi.loadProjectUsers(projectId, $scope.organization.id).then(function (response) {
                    if (response && response.data) {
                        $scope.team = response.data.users || [];
                        //Need to filter out users that are suspended in the organization

//  			var userList = _.map(_.filter($scope.users, function (orgUser) {
//			    return !(orgUser.status=="Suspended") && !_.some($scope.team, {'username': orgUser.username});
//			})


                    }
                });
                $scope.deselectProjects();
            }
        });

    }

})();
